<template>
    <section class="px-3 custom-scroll overflow-auto" :style="$route.name == 'tendero.almacen.creditos.dashboard' ? 'height:calc(100vh - 188px)' : '' ">
        <div class="row mx-0 mt-3">
            <div class="col text-general f-600 f-18">
                Cuentas por pagar en los próximo tres meses
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <graficaPorPagarTresMeses />
            </div>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col text-general f-600 f-18">
                {{ cuotasPedidos.length }} Pedidos - {{ separadorNumero(datosGenerales.total_deuda) }}
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div v-for="(pedido, idx) in cuotasPedidos" :key="idx" class="card-cuotas px-2 mx-3 my-2">
                <div class="row mx-0 justify-content-between my-3 mx-2">
                    <p class="f-14 tres-puntos text-general f-600 col px-0">
                        Pedido: {{ pedido.id_pedido_admin }}
                    </p>
                    <p class="f-14 tres-puntos text-general col px-0 text-right">
                        {{ formatearFecha(pedido.created_at_pedido,'DD MMM Y') }}
                    </p>
                </div>
                <hr class="my-2 mx-2 border" />
                <div class="row mx-0 justify-content-between my-3 mx-2 text-general">
                    <p class="f-14 tres-puntos col px-0">
                        Deuda:
                        <span>
                            {{ separadorNumero(pedido.deuda) }}
                        </span>
                    </p>
                    <p class="f-14 tres-puntos col px-0 text-right">
                        {{ formatearFecha(pedido.fecha_proxima_cuota,'DD MMM Y') }}
                    </p>
                </div>
            </div>
        </div>
        <div v-show="cuotasPedidos.length <= 0" class="row mx-0 py-3">
            <div class="col text-center text-muted">
                <p>No existen pedidos</p>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col text-general f-600 f-18">
                Balance del crédito
            </div>
        </div>
        <div class="d-flex mx-0 my-2 px-2 align-items-center">
            <div class="col">
                <echart :options="chartBar" />
            </div>
            <div class="">
                <div class="bg-light-f5 shadow br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p class="text-general">Deuda Actual</p>
                    <p class="text-general">{{ separadorNumero(datosGenerales.sumaActivos) }}</p>
                </div>
                <div class="bg-light-f5 shadow br-10 mb-3 text-center p-2 text-white" style="width:150px;">
                    <p class="text-general">Deuda vencida</p>
                    <p class="text-general">{{ separadorNumero(datosGenerales.sumaMorosos) }}</p>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5">
            <div class="col text-general f-600 f-18">
                Créditos dados al tendero durante este año
            </div>
        </div>
        <div class="row mx-0">
            <div class="col">
                <echart :options="calendario" />
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
import Creditos from '~/services/almacen/creditos_tendero'

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);
import moment from 'moment'

let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')

export default {
    components: {
        graficaPorPagarTresMeses: () => import('~/pages/almacen/tendero/creditos/components/graficaPorPagarTresMeses')
    },
    data(){
        return{
            loadingTabla: false,
            loadingGraficaBalance: false,
            loadingCalendario: false,
            cuentas_proximoMes: {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [120, 200, 150, 80, 70, 110, 130],
                    type: 'bar',
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(220, 220, 220, 0.8)'
                    },
                    barMaxWidth: 50,
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#FF9D32' },
                                {offset: 1, color: '#FF2525' },
                            ]
                        )
                    },
                }]
            },
            chartBar: {
                tooltip: {
                    trigger: 'axis',
                    formatter: params => {
                        const valor = this.separadorNumero(params[0].data)
                        return  params[0].axisValue +' - '+valor


                    },
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#283b56'
                        }
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [],
                    type: 'line',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0.2, color: '#E7E7E7' },
                                {offset: 1, color: '#F6F6F6' },
                            ]
                        )
                    },
                    itemStyle: {
                        color: colorGeneral
                    },
                }]
            },
            calendario: {
                title: {
                    text: '',
                    left: 'center',
                    textStyle: {
                        color : '#000000',
                        fontWeight: 'bold'
                    }
                },
                tooltip: {
                    position: 'top'
                },
                visualMap: {
                    min: 0,
                    max: 1000000,
                    calculable: true,
                    orient: 'horizontal',
                    left: 'center',
                    top: 'bottom',
                    inRange: {
                        color: ['#FF9D32','#FF2525']
                    },
                },
                calendar: [{
                    range: [
                        moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD'),
                        moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')],//['2020-01-01', '2021-01-01'], // necesita fechas por defecto
                    // range: ['2020-03-01', '2021-02-28'], // necesita fechas por defecto
                    yearLabel: {
                        show: false
                    },
                    monthLabel: {nameMap: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']},
                    dayLabel: {nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S'], margin: 13},
                    cellSize: ['auto', 20]
                }],

                series: [{
                    type: 'heatmap',
                    coordinateSystem: 'calendar',
                    calendarIndex: 0,
                    data: [],
                    tooltip: {
                        formatter: param => {
                            const data = param.data
                            return [
                                `<span style="color:${param.color};"></span> ${moment(data.value[0]).format('DD MMM YYYY')} <br/> ${this.separadorNumero(data.value[1])}<br/> `
                            ].join('')
                        }
                    }
                }]
            },
            pedidos: [
                {
                    n_Pedido: '1356255',
                    deuda: '30.000',
                    fecha_deuda: '25 Jul. 2020',
                    estado: 1
                },
                {
                    n_Pedido: '1356255',
                    deuda: '30.000',
                    fecha_deuda: '25 Jul. 2020',
                    estado: 2
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            cuotasPedidos: 'almacen/credito_tendero/cuotasPedidos',
            datosGenerales: 'almacen/credito_tendero/datosGenerales'
        }),
    },
    mounted(){
        this.graficaBalanceCredito()
        this.graficaCalendario()
    },
    methods: {
        anular(){
            this.$refs.modalAnular.toggle()
        },
        AbrirChat(){
            this.$refs.modalChat.toggle(this.tienda.created_by, 6) // 6 = tipo credito pedido -admin
        },
        registro_nuevo_pago(newPago){
            this.pagos_anual.push(newPago)
            if(this.tipo) this.cambioTipo() // para que recargue si esta en el calendario de pagos
            this.listarTabla()
        },
        se_anulo_credito(){
            this.listarTabla()
        },
        AbrirResgistrarPago(row){
            this.$refs.RegistrarPago.toggle(row);
        },
        cambioTipo(){
            if(this.tipo){
                this.getVirtulData3(this.pagos_anual)
            }else{
                this.getVirtulData3(this.cuotas_anual)
            }

        },
        async graficaBalanceCredito(){
            try {
                this.loadingGraficaBalance = true
                const {data} = await Creditos.graficaBalanceCredito(parseInt(this.$usuario.tienda.id))
                this.loadingGraficaBalance = false
                const EJE_X = data.grafica.map(e => e.dia)
                const EJE_y = data.grafica.map(e => e.value)
                this.chartBar.series[0].data = EJE_y
                this.chartBar.xAxis.data = EJE_X
                this.deuda = data.deuda

            } catch (e){
                this.loadingGraficaBalance = false
                this.error_catch(e)
            }
        },
        async graficaCalendario(){
            try {
                this.loadingCalendario = true
                const {data} = await Creditos.graficaCalendarioCreditoPorPagar(this.$usuario.tienda.id)
                this.loadingCalendario = false
                this.cuotas_anual = data.cuotas
                this.pagos_anual = data.pagos
                this.getVirtulData3(this.cuotas_anual)

            } catch (e){
                this.loadingCalendario = false
                this.error_catch(e)
            }
        },
        getVirtulData3(info){

            this.loadingGraficaBalance= true
            this.calendario.visualMap.inRange.color = this.tipo ? ['#ededed','#03D6BC']:['#FF9D32','#FF2525']
            const registros = info
            let hace_9_meses = moment().startOf('month').subtract(9, 'months').format('YYYY-MM-DD')
            let en_2_meses = moment().startOf('month').add(2, 'months').endOf('month').format('YYYY-MM-DD')
            var date = +echarts.number.parseDate(hace_9_meses);
            var end = +echarts.number.parseDate(en_2_meses);
            var dayTime = 3600 * 24 * 1000;
            var array = [];
            var cant = 0
            var max_valor = 0
            for (var time = date; time <= end; time += dayTime){
                cant = cant +1
                let acumulados = null
                registros.forEach(el => {
                    var fecha_dinamica = this.tipo ? moment(el.created_at).format('DD/MM/YYYY'): moment(el.fecha).format('DD/MM/YYYY')
                    if (fecha_dinamica=== echarts.format.formatTime('dd/MM/yyyy', time)){
                        acumulados = acumulados + parseFloat(el.valor)
                    }
                })
                if(acumulados > max_valor) max_valor = acumulados
                array.push(
                    {
                        value: [echarts.format.formatTime('yyyy-MM-dd', time), acumulados],
                        titulo: 'letrero'
                    })
            }
            this.calendario.visualMap.max = max_valor
            this.calendario.series[0].data = array
            this.calendario.title.text = `${hace_9_meses} - ${en_2_meses}`;
            this.loadingGraficaBalance= false
        }
    }
}
</script>
<style lang="scss" scoped>
.card-cuotas{
    width: 301px;
    min-height: 107px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
    background-color: #FFFFFF;
}
.red{
    color:#FF0000;
}
a{
    text-decoration: none;
}
</style>
